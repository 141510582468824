import React from "react"
import { Container, SEO } from "../components"

const Success = () => {
  return (
    <Container title="Thank You">
      <SEO />
      <div className="full fullMessageMargin">
        Your communication is very valuable to me. Appreciate you for taking
        time in writing to me.
        <br /> Will get back to you as soon as possible.
        <br /> <br /> <br /> Thank you!!!
        <br /> <strong className="special">Krishna Prasanth Vemuganti</strong>
      </div>
    </Container>
  )
}

export default Success
